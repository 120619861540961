<template>
    <div class="hacktoberfest">
        <v-row class="mb-8 pb-8 d-flex justify-center">
            <v-img max-width="100px" :src="require('../../public/logo-1000x1000-hacktoberfest.png')"></v-img>
        </v-row>
        <v-row class="example my-8 d-flex justify-center" style="position:relative" v-if="repos && repos.length === 0">
            <v-progress-circular class="progress" indeterminate size="300" v-if="loading['getRepos']"></v-progress-circular>
            <v-img :max-width="$vuetify.breakpoint.mobile ? '100%' : '800px'" src="https://github.brakecode.com/image/hacktoberfest.gif"></v-img>
        </v-row>
        <v-row class="form d-flex justify-center">
            <v-spacer></v-spacer>
            <v-col sm="6" lg="2" class="d-flex flex-column" v-if="repos && repos.length === 0">
                <v-text-field
                              dense
                              v-model="user"
                              label="GitHub User"
                              name="user"
                              outlined>
                </v-text-field>
                <v-btn class="text-body-1 mb-8 ml-1 hacktoberfestButton" outlined :loading="loading['getAuth']" @click="getAuth()">hacktoberfest<span class="font-weight-light font-italic mr-4 ify">ify</span> my repos</v-btn>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-col sm="6" lg="4" class="d-flex flex-column" v-if="repos && repos.length > 0">
                <v-row class="d-flex justify-center">
                    <v-col cols="4" class="d-flex flex-column">
                        <v-badge id="logout" overlap
                                 offset-x="30"
                                 offset-y="10"
                                 color="success" icon="fa-sign-out-alt">
                            <v-btn min-width="200px" :loading="loading['logout']" @click="logout()">
                                <v-icon color="black" size="xx-large">mdi-github</v-icon><span class="text-body-1 ml-2  text-uppercase">{{user}}</span>
                            </v-btn>
                        </v-badge>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                    <v-col sm="12" lg="8" class="d-flex flex-column">
                        <v-data-table disable-pagination hide-default-footer show-select
                                      v-model="selected"
                                      :dense="$vuetify.breakpoint.mobile || repos && repos.length > 10"
                                      :headers="headers"
                                      :items="repos"
                                      class="elevation-1">
                                      <template v-slot:item.full_name="{ item }">
                                <a class="repoLink" target="_blank" :href="`https://github.com/${item.full_name}`">{{item.full_name}}</a>
                                      </template>
                            <template v-slot:item.hacktoberfest="{ item }">
                                <v-chip
                                        :color="item.hacktoberfest ? 'yellow darken-2' : 'grey lighten-2'">
                                    {{ item.hacktoberfest ? 'Opted In' : 'Opted Out' }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                    <v-col cols="4" class="d-flex flex-column">
                        <v-btn class="mt-2 toggleButton" outlined :loading="loading['toggle']" @click="toggle()">toggle selected</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </div>
</template>
<style scoped>
.repoLink {
    text-decoration: none;
}
.hacktoberfestButton {
    text-transform: capitalize;
}
.ify {
    text-transform: uppercase;
}
.progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}
</style>
<script>
export default {
    name: "hacktoberfest",
    data() {
        return {
            selected: [],
            headers: [
                { text: "Repository", value: "full_name" },
                { text: "Hacktoberfest", value: "hacktoberfest" },
            ],
            loading: {
                getAuth: false,
                getRepos: false,
                toggle: false,
                logout: false
            },
        };
    },
    methods: {
        logout() {
            let self = this;
            self.loading["logout"] = true;
            document.cookie = `__secure-session-hacktoberfestify=; Domain=brakecode.com; SameSite=None; Secure; Expires=1181137020;`;
            self.repos = [];
            self.user = '';
            console.log("logged out");
            setTimeout(() => {
                self.loading["logout"] = false;
            }, 1000);
        },
        async getAuth() {
            let self = this;
            self.loading["getAuth"] = true;
            let response = await self.$backend.getAuth({ user: self.user });
            if (
                response.data === "bad_verification_code" ||
                response.data === "User parameter must be supplied."
            ) {
                self.$router.replace({ query: {} });
            } else if (response.data.redirect) {
                location.replace(response.data.redirect);
            }
            setTimeout(() => {
                self.loading["getAuth"] = false;
            }, 1000);
        },
        async getRepos() {
            let self = this;
            self.loading["getRepos"] = true;
            let response = await self.$backend.getRepos({
                code: self.code,
                state: self.state
            });
            if (
                response.data === "bad_verification_code" ||
                response.data === "Code parameter must be supplied."
            ) {
                //self.$router.replace({ query: {} });
            } else if (response.data.redirect) {
                location.replace(response.data.redirect);
            }
            self.$router.replace({ query: {} });
            self.storeSession(response);
            self.$store.commit("updateGitHubUser", response.data.user);
            self.$store.commit("addRepos", response.data.repos);
            setTimeout(() => {
                self.loading["getRepos"] = false;
            }, 1000);
        },
        storeSession(response) {
            if (!response.data || !response.data.tokenResponse) return;
            document.cookie = `__secure-session-hacktoberfestify=${JSON.stringify(response.data.tokenResponse)}; Domain=brakecode.com; SameSite=None; Secure;`
            this.$store.commit('updateGitHubAuth', response.data.tokenResponse);
        },
        async toggle() {
            let self = this;
            let access_token = self.$store.state.github.token.access_token;

            if (!access_token) {
                console.log('No GitHub access_token found.');
                return;
            }

            self.loading["toggle"] = true;
            let response = await self.$backend.toggle(access_token, {
                owner: self.user,
                repos: self.selected.map(repo => ({
                    hacktoberfest: repo.hacktoberfest,
                    topics: repo.topics.filter(topic => topic !== 'hacktoberfest'),
                    name: repo.name
                }))
            });
            if (response.data.find(r => r.status == 403)) location.replace(process.env.VUE_APP_GITHUB_APP);
            else if (response.data) self.$store.commit("updateRepos", response.data);
            setTimeout(() => {
                self.loading["toggle"] = false;
            }, 1000);
        },
        expand(index) {
            this.selected = index;
        },
    },
    computed: {
        user: {
            get() {
                return this.$store.state.github.user;
            },
            set(value) {
                this.$store.commit("updateGitHubUser", value);
            },
        },
        repos: {
            get() {
                return this.$store.state.github.repos;
            },
            set(value) {
                this.$store.commit("addRepos", value);
            },
        },
        code: function () {
            return this.$route.query.code;
        },
    },
    mounted() {
        if (this.code) this.getRepos();
    },
};
</script>
